import BookingComponent from '../components/shared/bookingFrame';
import { PageContext } from '../pageContext';
import React from 'react';
import Seo from '../components/Seo';
import SiteLayout from '../components/layout/layout';

interface Props {
  pageContext: PageContext;
}

const ContactPage: React.FC<Props> = ({ pageContext }: Props) => {
  return (
    <SiteLayout
      locale={pageContext.locale}
      hasHeader={false}
      componentName={pageContext.componentName}
    >
      <Seo
        title="Book corona test"
        description="Bestil en COVID-19/corona test hos PrivatCoronaTest allerede idag. Vi tilbyder både PCR-test og Antigen-test for private og virksomheder. Vi tilbyder PCR-test med svar samme dag. Vi tilbyder Antigen-test med svar på 15 minutter"
        keywords={[
          'Book coronatest',
          'Bestil tid corona',
          'Corona test',
          'privatcoronatest',
          'privat corona test',
          'bestil pcr test',
          'coronatest København',
          'Hurtig PCR test København',
          'COVID-19 test booking',
          'Coronatest booking'
        ]}
        pathname="booking"
      />
          <BookingComponent locale={pageContext.locale} />
    </SiteLayout>
  );
};
export default ContactPage;
