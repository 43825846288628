import ContactForm from '../contact/contactForm';
import React from 'react';
import { Row } from 'antd';
import styled from '@emotion/styled';
import { theme } from './theme';

import { H1, H1Bold, TextRegular, TextRegularBold } from './typography';

const StyledDiv = styled.div`
  position: relative;
  padding: 3rem 1rem;
  background: ${theme.colors.bgLight.white};
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`
type Props = {
  locale: string
}

const BookingComponent: React.FC<Props> = ({locale}: Props) => {
  return (
    <StyledDiv>
      <Row style={{maxWidth: '1000px', width: '100%'}}>

      <H1Bold style={{textAlign: 'center'}}>{locale === 'da-DK' ? 'Book en test' : 'Book a COVID-19 test'}</H1Bold>
      <TextRegular style={{textAlign: 'center', maxWidth: '1000px'}}>
        {locale === 'da-DK' ? `Her kan du bestille tid til PCR-test eller Antigen-test for COVID-19. Send os en forespørgsel ved at udfylde kontakteformularen nedenfor, så vender vi hurtigt tilbage med svar` :
         `Here you can book an appointment for a PCR test or Antigen test for COVID-19. Send us an inquiry by filling out the contact form below and we will get back to you shortly with an answer` }</TextRegular>
      </Row>
      <Row style={{maxWidth: '700px', width: '100%'}}>
       <ContactForm
                    locale={locale}
                    privateForm={true}
                    background={theme.colors.bgLight.white}
                  />
                  </Row>
      {/* <Helmet>
        <script src="//widget.simplybook.it/v2/widget/widget.js"></script>
      </Helmet> */}

          {/* <iframe
            scrolling="auto"
            className="sb-widget-iframe"
            width="100%"
            frameBorder="0"
            src="https://privatcoronatest.simplybook.it/v2/?widget-type=iframe&amp;theme=bookingtroll&amp;theme=bookingtroll&amp;timeline=modern&amp;datepicker=top_calendar#book"
            name="widget_0.8502109506452105"
            id="widget_0.8502109506452105"
            height="1600"
          ></iframe> */}
    </StyledDiv>
  );
};
export default BookingComponent;
